<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="page-tool">
      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
          >编辑</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delInfo"
          >删除</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
        default-expand-all
        :tree-props="{ children: 'children' }"
        row-key="id"
      >
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="sort" label="排序"> </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="栏目信息"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      top="5vh"
      @close="closeDialog"
    >
      <el-scrollbar style="height: 300px" class="myscrollbar">
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-form-item label="父类别" label-width="130px" prop="ParentId">
            <el-cascader
              v-model="forminfo.ParentId"
              :options="dataPageList"
              :props="{
                label: 'title',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
              }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="栏目名称" label-width="130px" prop="Title">
            <el-input
              v-model="forminfo.Title"
              autocomplete="off"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" label-width="130px" prop="Sort">
            <el-input-number v-model="forminfo.Sort"></el-input-number>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { saveCategory, getCategoryTree, getCategoryInfo } from '../../api/news'
import { getAuthorizeButtonColumnList } from '../../api/system'
export default {
  data() {
    return {
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      forminfoRules: {
        Title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
      },
      forminfo: {
        ID: '',
        ParentId: null,
        Sort: 99,
        Title: '',
      },
      plist: [],
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.forminfo.ID = ''
      } else {
        this.bjinfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveCategory(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取信息
    getDataPageList() {
      this.tableloading = true
      getCategoryTree({
        queryJson: JSON.stringify(),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.dataPageList = res.data
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getCategoryInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = true
            this.forminfo.ID = res.data.id
            this.forminfo.ParentId = res.data.parentId
            this.forminfo.Title = res.data.title
            this.forminfo.Sort = res.data.sort
          }
        })
        this.operation = 0
      }
    },
    delInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
      }
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-tool {
  padding: 20px 20px 0 20px;
}
.c_table {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #303030;
  font-weight: 700;
  padding: 5px 0;
}
</style>>
